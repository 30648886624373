var generic = generic || {};
var site = site || {};

site.quickshop = site.quickshop || {};
site.skuSelect = site.skuSelect || {};

$(document).on('sells_with_init', function (e, context) {
  Drupal.behaviors.sellsWithKitV1.init(context);
});

$(document).on('sells_with_reinit', function (e, context) {
  Drupal.behaviors.sellsWithKitV1.reinit(context);
});

(function ($) {
  Drupal.behaviors.sellsWithKitV1 = {
    $container: $(),
    $sellsWithWrapper: $(),
    $sellsWithOptions: $(),
    $sppDescriptionContainer: $(),
    $sppMainImage: $(),
    $sppStickyImage: $(),
    $addToBagBtn: $(),
    $addToFavorites: $(),
    sellsWithSkus: [],
    sellsWithProductData: {},
    collectionImages: {},
    imageSize: [],

    attach: function (context, settings) {
      var self = this;
      var $container = $('.js-sells-with', context);

      if ($container.data('sellsWith')) {
        self.init(context);
        self.imageSize = settings.image_size;
      }
    },

    isValidImage: function ($image) {
      var $brief = $image.closest('.mpp-product');

      if ($brief.length > 0) {
        return $brief.find('.js-sells-with').length > 0;
      }

      return true;
    },

    getDom: function (context) {
      var self = this;

      self.$container = $('.js-sells-with', context);
      self.$sellsWithWrapper = $('.js-sells-with-grid', self.$container);
      self.$sellsWithTitle = $('.js-sells-with-title', self.$container);
      self.$addToBagBtn = $('.js-add-to-bag-collection', context);
      self.$addToFavorites = $('.product-full .btn-add-to-favorites', context);
      self.$sppDescriptionContainer = site.client.isMobile ? self.$sellsWithTitle : $('.product-full__description--short, .abstract', context);
      self.$sppMainImage = site.client.isMobile ? $('.product-thumb img', context) : $('.product-full__image img:not(.hidden)', context);
      self.$sppMainImageV2 = $('.product-full__image--main-product', context); // @todo short term fix for backwards compatibility
      self.$sppMockImage = site.client.isMobile ? $('.js-product-image-small--wrapper img', context) : $('.product-full__image img.hidden', context);
      self.$sppMockImage = self.$sppMockImage.filter(function () {
        return self.isValidImage($(this));
      });
      self.$sppStickyImage = $('.js-sticky-add-to-bag__image', context);
      self.$mppContainer = $('.mpp-grid');
      self.$mppDescriptionContainer = $('.js-sells-with-title');
      if (self.$sppMainImageV2.length) {
        // redesign page uses same image selector on mobile as well
        self.$sppMainImage = self.$sppMainImageV2;
      }
      if (site.translations && site.translations.product && site.translations.product.sells_with_title) {
        self.$sellsWithTitle.html(site.translations.product.sells_with_title);
      } else {
        self.$sellsWithTitle.remove();
      }
    },

    generateCustomIdImages: function (optionProductCode, optionSkuId) {
      var self = this;
      var src = '';
      var productCode = '';
      var $optionMockImage = $();
      var $mock = $();

      if (self.$sppMockImage.length) {
        self.$sppMockImage.each(function () {
          $mock = $(this);
          src = typeof $mock.attr('src') === 'undefined' ? $mock.attr('data-src') : $mock.attr('src');
          if (typeof src === 'undefined') {
            return true;
          }

          productCode = $mock.data('productCode');
          src = src.replace(productCode, optionProductCode + '_' + productCode);
          $optionMockImage = $mock.clone(true).removeAttr('style').attr('src', src).attr('data-skuid', $mock.data('skuid').replace('SKU', '') + optionSkuId).data('skuid', productCode + optionSkuId);
          $mock.after($optionMockImage[0]);
        });
      } else {
        // use main image to generate the dynamic images of the final product
        src = typeof self.$sppMainImage.attr('src') === 'undefined' ? self.$sppMainImage.attr('data-src') : self.$sppMainImage.attr('src');
        if (typeof src === 'undefined') {
          return true;
        }
        productCode = self.$sppMainImage.data('productCode');
        src = src.replace(productCode, optionProductCode + '_' + productCode);
        self.collectionImages[optionSkuId] = src;
      }
    },

    showOptionsSkus: function () {
      var self = this;

      self.sellsWithSkus = self.$container.data('sellsWith').split(',');
      self.sellsWithSkus = self.sellsWithSkus.map(function (val) {
        return val.trim();
      });
      var productFields = ['PROD_RGN_NAME', 'SHORT_DESC', 'SUB_LINE'];
      var skuFields = ['SKU_ID', 'SKU_BASE_ID', 'PRODUCT_ID', 'PRODUCT_CODE', 'SMALL_IMAGE', 'LARGE_IMAGE', 'IMAGE_5UP', 'INVENTORY_STATUS'];
      var prod_param = [
        {
          'skus': self.sellsWithSkus,
          'product_fields': productFields,
          'sku_fields': skuFields,
          'get_product': 1
        }
      ];

      generic.jsonrpc.fetch({
        'method': 'prodcat',
        'params': prod_param,
        'onSuccess': function (jsonRpcResponse) {
          $(document).trigger('sells_with_rpc_complete', jsonRpcResponse);
        }
      });
    },

    processOptions: function () {
      var self = this;
      var thumbnail = '';
      var unavailableClass = '';

      if (Object.keys(self.sellsWithProductData).length > 0) {
        self.$sellsWithWrapper.empty();
        _.each(self.sellsWithProductData, function (data) {
          thumbnail = typeof data['skuData']['SMALL_IMAGE'] !== 'undefined' ? '<img src="' + data['skuData']['SMALL_IMAGE'] + '"></img>' : '';
          thumbnail = typeof data['skuData']['IMAGE_5UP'] !== 'undefined' && thumbnail === '' ? '<img src="' + data['skuData']['IMAGE_5UP'] + '"></img>' : thumbnail;

          unavailableClass = parseInt(data['skuData']['INVENTORY_STATUS']) !== 1 ? 'unavailable' : '';
          self.$sellsWithWrapper.append('<li data-secondary-sku-base-id="' + data['skuData']['SKU_BASE_ID'] + '" class="' + unavailableClass + '" >' + thumbnail + '</li>');
          self.generateCustomIdImages(data['skuData']['PRODUCT_CODE'].replace(/\W/g, ''), data['skuData']['SKU_BASE_ID']);
        });
        self.$container.removeClass('hidden');
        self.$sellsWithOptions = self.$sellsWithWrapper.find('li:not(".unavailable")');
        self.$container.trigger('options_loaded');
      }
    },

    addEvents: function () {
      var self = this;

      $(document).one('sells_with_rpc_complete', function (event, jsonRpcResponse) {
        var sellsWithData = jsonRpcResponse.getValue();

        if (typeof sellsWithData.products === 'undefined') {
          return;
        }
        sellsWithData.products.map(function (productData, i) {
          if (typeof sellsWithData.skus[i] === 'undefined' || typeof sellsWithData.skus[i]['SKU_BASE_ID'] === 'undefined') {
            return true;
          }
          self.sellsWithProductData[sellsWithData.skus[i]['SKU_BASE_ID']] = { 'productData': productData };
          self.sellsWithProductData[sellsWithData.skus[i]['SKU_BASE_ID']]['skuData'] = sellsWithData.skus[i];
        });
        self.processOptions();
      });
      self.$container.on('options_loaded', function () {
        var currentName;
        var currentDescription;
        var descriptionHtml;
        var combinationSku;
        var $mainAddToBagBtn = $();
        var subLine = Drupal.settings.globals_variables && Drupal.settings.globals_variables.giftset_component ? Drupal.settings.globals_variables.giftset_component : false;

        self.$addToBagBtn.map(function () {
          if ($(this).data('skubaseid')) {
            $mainAddToBagBtn = $(this);
          }
        });
        self.$sellsWithOptions.on('click', function (event, autoload) {
          self.$sellsWithOptions.not($(this)).removeClass('active');
          $(this).addClass('active');
          self.$addToBagBtn
            .attr('data-secondary-sku-base-id', $(this).data('secondarySkuBaseId'))
            .data('secondarySkuBaseId', $(this).data('secondarySkuBaseId'))
            .removeClass('sells-with--disabled')
            .removeClass('js-click-unbound');
          if (!autoload) {
            currentName = subLine ? $('h1.product-full__name').text() : self.sellsWithProductData[$(this).data('secondarySkuBaseId')]['productData']['PROD_RGN_NAME'];
            currentDescription = subLine ? self.sellsWithProductData[$(this).data('secondarySkuBaseId')]['productData']['SUB_LINE'] : self.sellsWithProductData[$(this).data('secondarySkuBaseId')]['productData']['SHORT_DESC'];
            descriptionHtml = subLine ? '<div class="clear js-sells-with-description sells-with-description"><span class="sells-with--description--product-name">' + currentDescription + '</span>: ' + currentName + '</div>' : '<div class="clear js-sells-with-description sells-with-description"><span class="sells-with--description--product-name">' + currentName + '</span>: ' + currentDescription + '</div>';
            if (self.$mppContainer.length > 0) {
              self.$mppDescriptionContainer.parent().find('.js-sells-with-description').remove();
              self.$mppDescriptionContainer.after(descriptionHtml);
            } else {
              combinationSku = { 'SKU_ID': self.$addToBagBtn.filter('[data-skubaseid]').attr('data-skubaseid') + $(this).data('secondarySkuBaseId') };
              self.swapSkuImage(combinationSku, $(this).data('secondarySkuBaseId'), $mainAddToBagBtn.data('skubaseid'));
              self.$sppDescriptionContainer.parent().find('.js-sells-with-description').remove();
              self.$sppDescriptionContainer.after(descriptionHtml);
            }
          }
        });
        self.isEditCollection();
      });
    },

    isEditCollection: function () {
      var self = this;
      var params = self.getSearchParameters();

      if (!params.collection_id || !params.cart_id || !params.sku_base_id) {
        return;
      }
      self.$addToBagBtn.attr('data-collectionid', params.collection_id).data('collectionid', params.collection_id);
      self.$addToBagBtn.attr('data-cartid', params.cart_id).data('cartid', params.cart_id);
      if (params.collection_name) {
        self.$addToBagBtn.attr('data-collectionname', params.collection_name).data('collectionname', params.collection_name);
      }
      self.setSelectedSku(params);
    },

    setSelectedSku: function (params) {
      var self = this;
      var skus = params.sku_base_id.trim().split(',');

      for (var a in skus) {
        if (skus.hasOwnProperty(a)) {
          skus[a] = parseInt(skus[a], 10);
          self.$sellsWithOptions.filter('[data-secondary-sku-base-id="' + skus[a] + '"]').trigger('click');
        }
      }
    },

    getSearchParameters: function () {
      var self = this;
      var prmstr = window.location.search.substr(1);

      return prmstr !== null && prmstr !== '' ? self.transformToAssocArray(prmstr) : {};
    },

    transformToAssocArray: function (prmstr) {
      var params = {};
      var prmarr = prmstr.split('&');

      for (var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split('=');

        params[tmparr[0]] = tmparr[1];
      }

      return params;
    },

    swapSkuImage: function (combinationSku, optionSkuId, baseSku) {
      var self = this;
      var src = '';
      var skuData = {};
      var $newImageMock = $();
      var smallSrc = '';

      if (site.client.isMobile) {
        src = $('.js-product-image-small--wrapper img[data-skuid="' + combinationSku.SKU_ID + '"]', $(document)).attr('src');
        if (!!self.collectionImages[optionSkuId] && !src) {
          src = self.collectionImages[optionSkuId];
        }
        self.$sppMainImage.attr('src', src);
      } else {
        // check if the skuSelect is set or not, this is for the redesign SPP's
        if (typeof site.skuSelect.prototype === 'object' && typeof site.skuSelect.prototype.skuSelect === 'function') {
          skuData = site.skuSelect.prototype.getSku(baseSku);
          skuData.IMAGE_ZOOM = self.collectionImages[optionSkuId];
          site.skuSelect.prototype.skuSelect(self.$addToBagBtn, self.$addToFavorites, skuData, self.$documentObj);
          smallSrc = self.getStickyImagePath(self.collectionImages[optionSkuId]);
          self.$sppStickyImage.attr('src', smallSrc);
        } else {
          // if we are on PC we can use the existing functionality
          if (!!self.collectionImages[optionSkuId]) {
            if (!self.$sppMainImage.parent().find('[data-skuid="' + combinationSku.SKU_ID + '"]').length) {
              if (!self.$sppMainImage.hasClass('.product-full__image--main-product')) {
                // V1 SPP
                $newImageMock = self.$sppMainImage.clone(true)
                  .removeClass()
                  .removeAttr('style')
                  .addClass('hidden')
                  .attr('src', self.collectionImages[optionSkuId])
                  .attr('data-skuid', combinationSku.SKU_ID)
                  .data('skuid', combinationSku.SKU_ID);
                self.$sppMainImage.after($newImageMock);
                // if the SPP has only 1 image, we need grab the new images to use for future swaps
                self.$sppMockImage = $(self.$sppMockImage.selector, self.$sppMockImage.prevObject);
              } else {
                // V2 spp
                self.$sppMainImage.attr('src', self.collectionImages[optionSkuId]);
              }
            }
          }
          $newImageMock = self.$sppMockImage.filter('[data-skuid="' + combinationSku.SKU_ID + '"]');
          self.$sppMainImage.attr('src', $newImageMock.attr('src'));
          if (typeof $newImageMock.attr('src') !== 'undefined') {
            smallSrc = self.getStickyImagePath($newImageMock.attr('src'));
            self.$sppStickyImage.attr('src', smallSrc);
          }
        }
      }
    },

    disableAddToBag: function () {
      var self = this;

      self.$addToBagBtn.addClass('sells-with--disabled js-click-unbound');
    },

    getStickyImagePath: function (path) {
      var self = this;
      var replace = new RegExp(self.imageSize['image_spp_size'] + '|' + self.imageSize['image_zoom_size'], 'g');

      path = path.replace(replace, self.imageSize['sticky_image_size']);

      return path;
    },

    init: function (context) {
      $(context).addClass('js-sells-with--context');
      var self = this;

      self.getDom(context);
      self.resetContext(context);
      self.addEvents();
      if (self.$container.data('sellsWith')) {
        self.showOptionsSkus();
      }
    },

    reinit: function (context) {
      var self = this;

      self.getDom(context);
      self.processOptions();
      self.resetContext(context);
      self.addEvents();
    },

    resetContext: function (context) {
      var self = this;

      $('.js-sells-with--context').not($(context)).hide();
      self.$sellsWithOptions.removeClass('active');
      self.$mppDescriptionContainer.parent().find('.js-sells-with-description').remove();
      self.disableAddToBag();
    }
  };
})(jQuery);

/*
 * Helper functions for generating the right price for Clinique ID
 * products
 */
site.sellsWith = {
  getPriceFormat: function (formattedPrice) {
    var regex = /([\d]+(?:(,|.)?)+?[\d]+)+/gi;
    var matches = regex.exec(formattedPrice);
    var price = matches[0];
    var priceFormat = [];

    priceFormat['decimals_delimiter'] = false;
    var decimalsDelimiterVal = Drupal.settings && Drupal.settings.globals_variables ? Drupal.settings.globals_variables.decimals_delimiter_val : '.';
    var thousandsDelimiterVal = Drupal.settings && Drupal.settings.globals_variables ? Drupal.settings.globals_variables.thousands_delimiter_val : ',';

    priceFormat['thousands_delimiter'] = price.indexOf(thousandsDelimiterVal) !== -1 ? thousandsDelimiterVal : '';
    var delimiterPoint = price.indexOf(decimalsDelimiterVal);
    var decimalDelimiterPosition = -1;

    if (delimiterPoint !== -1) {
      priceFormat['decimals_delimiter'] = decimalsDelimiterVal;
      decimalDelimiterPosition = delimiterPoint + 1;
    }
    priceFormat['decimals_count'] = decimalDelimiterPosition !== -1 ? price.substring(decimalDelimiterPosition).length : false;

    return priceFormat;
  },

  formatPrice: function (price, priceFormat) {
    if (priceFormat['decimals_delimiter'] || priceFormat['thousands_delimiter']) {
      return this.numberFormat(price, priceFormat['decimals_count'], priceFormat['decimals_delimiter'], priceFormat['thousands_delimiter']);
    }

    return price;
  },

  numberFormat: function (number, decimals, dec_point, thousands_sep) {
    number = number * 1;
    var str = number.toFixed(decimals ? decimals : 0).toString().split(dec_point ? dec_point : '.');
    var parts = [];

    for (var i = str[0].length; i > 0; i -= 3) {
      parts.unshift(str[0].substring(Math.max(0, i - 3), i));
    }
    str[0] = parts.join(thousands_sep ? thousands_sep : ',');

    return str.join(dec_point ? dec_point : '.');
  }
};
